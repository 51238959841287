<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" @click="onRefresh" size="small"
        >刷新</el-button
      >
      <el-button
        icon="el-icon-download"
        type="primary"
        @click="exportExcel"
        size="small"
        >导出</el-button
      >
      <el-button
        icon="el-icon-download"
        type="warning"
        size="small"
        @click="batchDownLoad"
        >批量凭证</el-button
      >
      <el-button
        icon="el-icon-s-flag"
        type="success"
        size="small"
        @click="reimbursementBatch"
        >批量报账</el-button
      >
      <div class="searchCon">
        <div class="searchItem">
          <el-input
            size="small"
            style="width: 130px"
            v-model="searchQuery.applicantName"
            placeholder="按申请人查询"
          ></el-input>
        </div>
        <div class="searchItem" v-if="treeShow">
          <el-select
            clearable
            style="width: 180px"
            v-model="searchQuery.departmentId"
            placeholder="按部门查询"
            size="small"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchItem" v-if="!treeShow">
          <el-cascader
            :options="treeList"
            size="small"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              emitPath: false,
              checkStrictly: true,
            }"
            clearable
            placeholder="按部门查询"
            v-model="searchQuery.departmentId"
          ></el-cascader>
        </div>

        <div class="searchItem">
          <span>日期选择</span>
          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getRangDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            size="small"
          >
          </el-date-picker>
        </div>
        <div class="searchItem">
          <el-select
            clearable
            style="width: 120px"
            v-model="searchQuery.isReimbursement"
            placeholder="是否报账"
            size="small"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已报账" :value="0"></el-option>
            <el-option label="未报账" :value="1"></el-option>
          </el-select>
        </div>
        <div class="searchItem">
          <el-select
            clearable
            style="width: 120px"
            v-model="searchQuery.isConsume"
            placeholder="是否消费"
            size="small"
          >
            <el-option label="已消费" :value="0"></el-option>
            <el-option label="未消费" :value="1"></el-option>
          </el-select>
        </div>
      </div>
      <el-button
        icon="el-icon-search"
        type="primary"
        @click="handleSearch"
        size="small"
        >查询</el-button
      >
      <el-button
        icon="el-icon-refresh"
        @click="refreshObj(searchQuery)"
        size="small"
        >重置</el-button
      >
    </div>
    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      @row-dblclick="onEdit"
    >
      <el-table-column type="selection" width="50" />

      <el-table-column label="申请人" prop="applicantName" width="80" />

      <el-table-column label="点餐类型" prop="mealType" width="120">
        <template slot-scope="scope">
          <span :style="{ color: filterMealType(scope.row.mealType, 1) }">
            {{ filterMealType(scope.row.mealType, 0) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="所属分局" prop="departmentName" width="150" />
      <el-table-column label="申请金额" prop="amount" width="80" />
      <el-table-column label="消费金额" prop="usedAmount" width="80" />

      <el-table-column label="人数" prop="peopleNumber" width="60" />
      <el-table-column label="申请日期" prop="applicantDate" width="120" />
      <!-- <el-table-column label="用餐时间" prop="mealTime" width="120" /> -->
      <el-table-column label="申请理由" prop="reason" />
      <el-table-column label="审批状态" prop="status" width="80">
        <template slot-scope="scope">
          <span :style="{ color: filterType(scope.row.status, 1) }">
            {{ filterType(scope.row.status, 0) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="审批人" prop="approverName" width="80" />

      <el-table-column label="是否报账" prop="isReimbursement" width="100">
        <template slot-scope="scope">
          <span
            :style="{
              color: filterisReimbursement(scope.row.isReimbursement, 1),
            }"
          >
            {{ filterisReimbursement(scope.row.isReimbursement, 0) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="340" align="left">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="jumpDetail(scope.row)">
            详情
          </el-button>
          <el-button
            type="danger"
            size="small"
            @click="handleApproval(scope.row)"
            v-if="scope.row.status == 0 && handleShow"
          >
            审批
          </el-button>
          <el-button
            v-if="scope.row.isReimbursement == 0"
            type="warning"
            size="small"
            @click="cancelAccount(scope.row.id)"
          >
            撤回报账
          </el-button>
          <el-button
            type="success"
            size="small"
            @click="showDowvoucher(scope.row)"
          >
            凭证
          </el-button>
          <el-button
            v-if="scope.row.status == 1 && scope.row.isReimbursement == 1"
            type="danger"
            size="small"
            @click="showReckoning(scope.row.id)"
          >
            报账
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.currentPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
    <div class="batchPic">
      <div
        :id="'item_' + index"
        v-for="(item, index) in contentArr"
        :key="index"
      >
        <div style="background: #fff" v-html="item"></div>
      </div>
    </div>
    <!-- 编辑信息 -->
    <el-dialog title="申请详情" width="50%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form
          ref="ruleForm"
          :model="form"
          label-width="120px"
          readonly
          inline
        >
          <el-form-item label="申请人" prop="applicantName">
            <el-input v-model="form.applicantName" type="text" clearable />
          </el-form-item>
          <el-form-item label="点餐类型" prop="mealType">
            <el-input
              :value="filterMealType(form.mealType, 0)"
              type="text"
              clearable
            />
          </el-form-item>
          <el-form-item label="部门" prop="departmentName">
            <el-input v-model="form.departmentName" type="text" clearable />
          </el-form-item>
          <el-form-item label="申请金额" prop="amount">
            <el-input v-model="form.amount" type="text" clearable />
          </el-form-item>
          <el-form-item label="申请日期" prop="applicantDate">
            <el-input v-model="form.applicantDate" type="text" clearable />
          </el-form-item>
          <el-form-item label="用餐名单" prop="mealTime">
            <el-input v-model="form.mealPersonnel" type="text" clearable />
          </el-form-item>
          <el-form-item label="申请理由" prop="reason">
            <el-input v-model="form.reason" type="text" clearable />
          </el-form-item>
          <el-form-item label="申请状态" prop="status">
            <el-input
              :value="filterType(form.status, 0)"
              type="text"
              clearable
            />
          </el-form-item>
          <el-form-item label="审批人" prop="approverName">
            <el-input v-model="form.approverName" type="text" clearable />
          </el-form-item>
          <el-form-item label="审批意见" prop="approvalOpinion">
            <el-input v-model="form.approvalOpinion" type="text" clearable />
          </el-form-item>
          <el-form-item label="报账状态" prop="isReimbursement">
            <el-input
              :value="filterisReimbursement(form.isReimbursement, 0)"
              type="text"
              clearable
            />
          </el-form-item>
          <el-form-item label="报账图片" prop="status">
            <el-image
              v-for="item in imgList"
              :key="item"
              style="width: 100px; height: 100px; margin-right: 10px"
              :src="item"
              :preview-src-list="imgList"
            >
            </el-image>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 申请报账 -->
    <el-dialog
      :title="handleType == 0 ? '申请报账' : '批量报账'"
      :visible.sync="reckoningVisible"
      width="30%"
      @close="reckoningVisible = false"
    >
      <div>
        <el-form
          class="reckoningForm"
          ref="ruleForm"
          :model="reckoningForm"
          label-width="120px"
          readonly
          inline
        >
          <el-form-item label="报账备注" prop="reimbursementNote">
            <el-input
              style="width: 400px"
              v-model="reckoningForm.reimbursementNote"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 10 }"
              clearable
            />
          </el-form-item>

          <el-form-item label="报账凭证">
            <el-upload
              class="upload-demo marginBom"
              :action="`${baseUrl}/file/upload`"
              :on-success="fileUpSuc"
              :on-error="fileUpErr"
              :before-upload="beforeUpload"
              list-type="picture-card"
              :file-list="fileList"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="reckoningVisible = false">取消</el-button>
        <el-button type="primary" @click="submitReckoning">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="下载凭证"
      :visible.sync="dowvoucherVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="dowvoucherVisible = false"
    >
      <div
        style="margin: 0 auto; padding: 0 20px; background: #fff"
        id="poster"
        class="echarts renderjs"
      >
        <div id="my-node">
          <div style="background: #fff" v-html="htmlContent"></div>
          <!-- <rich-text :nodes="htmlContent"></rich-text> -->
        </div>
      </div>
      <span slot="footer">
        <!-- <el-button @click="dowvoucherVisible = false">Cancel</el-button> -->
        <el-button type="primary" @click="shotPic">下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/common";
import { download } from "@/utils/request";
import domtoimage from "dom-to-image";
import JSZip from "jszip";
import FileSaver from "file-saver";
import * as imageConversion from "image-conversion";
export default {
  name: "SleepRoom",
  data() {
    return {
      fileList: [],
      handleShow: false,
      form: {
        id: "",
        name: "",
        address: "",
        telephone: "",
        landphone: "",
        linkman: "",
        description: "",
        standard: 0,
        status: 0,
        isOpen: 1,
        mealType: 0,
        isReimbursement: 0,
      },
      contentArr: [],
      imageList: [],
      curNum: 0,
      fullLoading: null,
      htmlContent: "",
      dowvoucherVisible: false,
      owvoucherData: {},
      imgList: [],
      searchQuery: {
        departmentId: "",
        startDate: "",
        endDate: "",
        isReimbursement: "",
        isConsume: 0,
      },
      query: {
        name: "",
        datearray: "",
        status: "",
      },
      daterange: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
        apiName: "/meituanApplication",
        userId: sessionStorage.getItem("userId"),
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      departmentList: [],
      treeList: [],
      treeShow: false,
      handleType: 0,
      reckoningForm: {
        id: 0,
        reimbursementNote: "",
        reimbursementPicture: "",
        picList: [],
      },
      reckoningId: 0,
      reckoningVisible: false,
      baseUrl: "https://police.dznc-tech.com:3000",
    };
  },
  created() {
    this.fetchData();
    this.getDepartList();
  },
  mounted() {
    if (sessionStorage.getItem("roleCode") == "admin") {
      this.handleShow = true;
    }
  },
  methods: {
    //批量报账
    async reimbursementBatch() {
      if (this.multipleSelection.length < 1) {
        this.$message.error("请至少选择一条数据!");
      } else {
        this.reckoningVisible = true;
        this.handleType = 1;
      }
    },
    // 上传图片
    beforeUpload(file) {
      const _that = this;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt100KB = file.size / 1024 < 100;
      if (!isJpgOrPng) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
        return false;
      }
      return new Promise((resolve) => {
        // 小于100KM 不压缩
        if (isLt100KB) {
          resolve(file);
        }
        // 压缩到400KB,这里的400就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 99).then((res) => {
          console.log(res);
          resolve(res);
        });
      });
    },
    fileUpSuc(e) {
      if (e) {
        this.reckoningForm.picList.push(e.savePath);
      }
    },
    fileUpErr(e) {
      this.$message.error("上传失败!");
      console.log("上传失败", e);
    },
    //显示弹框填写报账信息
    showReckoning(id) {
      this.reckoningVisible = true;
      this.reckoningId = id;
      this.handleType = 0;
    },

    // 提交报账
    async submitReckoning() {
      let { reckoningForm, reckoningId } = this;
      if (reckoningForm.picList.length <= 0) {
        this.$message.error("请上传报账凭证!");
        return false;
      }

      let ids = [];

      if (this.handleType == 0) {
        ids.push(this.reckoningId);
      } else {
        this.multipleSelection.forEach((item) => {
          ids.push(item.id);
        });
      }

      let data = {
        ids: ids,
        apiName: "/meituanApplication/reimbursementBatch",
        reimbursementNote: reckoningForm.reimbursementNote,
        reimbursementPicture: reckoningForm.picList.toString(),
      };

      let res = await api.commonPost(data);

      if (res.code == 200) {
        this.$message.success("报账成功!");
        this.reckoningVisible = false;
        this.fileList = [];
        this.reckoningForm.picList=[];
        this.fetchData();
      }
    },
    //批量下载凭证
    async batchDownLoad() {
      if (this.multipleSelection.length < 1) {
        this.$message.error("请至少选择一条数据!");
      } else {
        //全局loading
        this.fullLoading = this.$loading({
          lock: true,
          text: "正在打包",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        for (let index = 0; index < this.multipleSelection.length; index++) {
          const element = this.multipleSelection[index];
          let res = await api.commonGet({
            apiName: `/meituanApplication/generateBillingHtml?id=${element.id}`,
          });
          this.contentArr.push(res.data);
        }

        this.divToimage();
      }
    },

    //采用递归的形式去生成图片

    async divToimage() {
      if (this.curNum < this.contentArr.length) {
        let obj = this.multipleSelection[this.curNum];

        const node = document.getElementById("item_" + this.curNum);
        domtoimage.toJpeg(node).then(async (dataUrl) => {
          this.imageList.push({
            name:
              obj.applicantDate +
              "-" +
              obj.applicantName +
              "-" +
              obj.usedAmount +
              "元",
            url: dataUrl,
          });
          await this.divToimage();
        });
        this.curNum++;
      } else {
        this.curNum = 0;
        this.downloadZip();
      }
    },
    //打包成zip
    downloadZip() {
      let that = this;
      const zip = new JSZip();
      this.imageList.forEach((item) => {
        const fileName = item.name + ".png";
        zip.file(fileName, item.url.substring(22), { base64: true }); //向zip中添加文件
      });
      zip.generateAsync({ type: "blob" }).then((content) => {
        // 生成二进制流
        FileSaver.saveAs(content, "点餐凭证.zip"); // 利用file-saver保存文件
        this.imageList = [];
        this.contentArr = [];
        this.fullLoading.close();
      });
    },

    handleSearch() {
      this.pageInfo.currentPage = 1;
      this.fetchData();
    },
    //撤回报账

    async cancelAccount(id) {
      let res = await api.commonPost({
        apiName: "/meituanApplication/cancelAccount",
        id: id,
      });
      if (res.code == 200) {
        this.$message.success("撤回报账成功");
        this.fetchData();
      } else {
        this.$message.error("撤回报账失败");
      }
    },
    //获取当前部门子部门

    async handleApproval(row) {
      console.log(row);
      let res = await api.commonPost({
        apiName: "/meituanApplication/approve",
        id: row.id,
        status: 1,
        approverId: row.approverId,
        approvalOpinion: "系统故障，技术员代审批",
      });
      if (res.code == 200) {
        this.$message.success("审核成功");
        this.fetchData();
      }
    },
    //导出不能传空字段
    async exportExcel() {
      let obj = {};

      for (const key in this.searchQuery) {
        if (this.searchQuery[key]) {
          obj[key] = this.searchQuery[key];
        }
      }

      let res = await download({
        url: "/meituanDepartmentApprover/exportExcel",
        params: obj,
      });
    },
    refreshObj(obj) {
      for (const key in obj) {
        this.$set(obj, key, "");
      }

      this.fetchData();
    },
    getRangDate() {
      this.searchQuery.startDate = this.daterange[0];
      this.searchQuery.endDate = this.daterange[1];

      this.fetchData();
    },
    onPageChange(page) {
      this.pageInfo.currentPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onRefresh() {
      this.fetchData();
    },
    //添加

    async jumpDetail(obj) {
      try {
        let res = await api.commonGetWidthHeader({
          apiName: "/file/getImageDomainName",
        });

        console.log(res);
        this.imgList = [];
        let array = obj.reimbursementPicture.split(",");

        array.forEach((element) => {
          this.imgList.push(res.data.urlPath + element);
        });

        this.form = obj;
        this.selectDlgShow = true;
      } catch (error) {
        console.log(error);
      }
    },

    filterType(num, index) {
      let typeObj = {
        0: ["未审批", "#409EFF"],
        1: ["审批通过", "#67C23A"],
        2: ["审批拒绝", "#F56C6C"],
        3: ["取消申请", "#909399"],
        4: ["通过初审", "#909399"],
      };
      return typeObj[parseInt(num)][index];
    },
    filterisReimbursement(num, index) {
      let typeObj = {
        0: ["已报账", "#409EFF"],
        1: ["未报账", "#F56C6C"],
      };
      return typeObj[parseInt(num)][index];
    },

    filterMealType(num, index) {
      let typeObj = {
        0: ["误餐点餐", "#606266"],
        1: ["招待用餐", "#409EFF"],
      };
      return typeObj[parseInt(num)][index];
    },
    onAdd() {
      this.form = {
        id: "",
        name: "",
        address: "",
        telephone: "",
        landphone: "",
        linkman: "",
        description: "",
        standard: 0,
        suite: 0,
        isOpen: 1,
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        address: row.address,
        telephone: row.telephone,
        landphone: row.landphone,
        linkman: row.linkman,
        description: row.description,
        standard: row.standard,
        suite: row.suite,
        isOpen: row.isOpen,
      };
      this.selectDlgShow = true;
    },

    async getDepartList() {
      //如果是部门管理员

      if (sessionStorage.getItem("roleCode") == "meituanadmin") {
        this.treeShow = true;
        let res = await api.commonGet({
          apiName: "/department/findChildList",
          id: sessionStorage.getItem("departmentId"),
        });
        this.departmentList = res.data;
      } else {
        this.treeShow = false;
        let res = await api.commonPost({ apiName: "/department/getTreeList" });

        this.treeList = res.data;
      }
    },

    //查询列表
    fetchData() {
      this.listLoading = true;
      let obj = {};
      for (const key in this.searchQuery) {
        if (this.searchQuery[key] !== "") {
          this.$set(obj, key, this.searchQuery[key]);
        }
      }

      let newObj = Object.assign({}, obj, this.pageInfo);

      api.commonGet(newObj).then((response) => {
        this.list = response.data.data;
        this.pageInfo.total = response.data.total;
        this.pageInfo.currentPage = response.data.currentPage;
        this.pageInfo.pageSize = response.data.pageSize;
        this.listLoading = false;
      });
    },
    //获取剩余数量
    // fetchLessRoomNums(roomId) {
    //   getLessRoomNums({ roomId: roomId }).then(() => {
    //     this.le;
    //   });
    // },
    //批量删除
    // delAll() {
    //   if (this.multipleSelection.length == 0) {
    //     this.$message.warning("请至少选择一条数据");
    //     return;
    //   }
    //   this.$confirm("您确定要删除所选择的数据, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     var ids = [];
    //     this.multipleSelection.forEach((item) => {
    //       ids.push(item.id);
    //     });
    //     delRoom({ ids: ids })
    //       .then(() => {
    //         this.$message({
    //           type: "success",
    //           message: "删除成功!",
    //         });
    //         this.fetchData();
    //       })
    //       .catch(() => {
    //         this.$message.error("删除错误！");
    //       });
    //   });
    // },
    //删除
    // onDel(data) {
    //   this.$confirm("您确定要删除此条数据吗, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     delRoom({ id: data.id })
    //       .then(() => {
    //         this.$message({
    //           type: "success",
    //           message: "删除成功!",
    //         });
    //         this.fetchData();
    //       })
    //       .catch(() => {
    //         this.$message.error("删除错误！");
    //       });
    //   });
    // },
    //验证表单
    // submitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       this.save();
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    //保存与修改
    // save() {
    //   this.dlgLoading = true;
    //   saveRoom(this.form)
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         this.$message({
    //           message: "保存成功",
    //           type: "success",
    //         });
    //         this.selectDlgShow = false;
    //         this.dlgLoading = false;
    //         this.fetchData();
    //       } else {
    //         this.$message.error("保存失败");
    //       }
    //     })
    //     .catch(() => {
    //       this.dlgLoading = false;
    //     });
    // },
    //开关切换
    // changeSwitch(data, b) {
    //   saveRoom(b).then((resp) => {
    //     if (resp.code == 200) {
    //       this.$message({
    //         message: "修改成功",
    //         type: "success",
    //       });
    //     } else {
    //       this.$message.error(resp.msg);
    //     }
    //   });
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取凭证HTML模板
    async showDowvoucher(row) {
      console.log("row", row);

      let id = row.id;
      let res = await api.commonGet({
        apiName: `/meituanApplication/generateBillingHtml?id=${id}`,
      });
      console.log("resssssss.", res);

      if (res.code == 200) {
        this.htmlContent = res.data;
        this.dowvoucherVisible = true;
      }

      // this.dowvoucherData = row;
    },
    // 下载凭证
    shotPic() {
      const node = document.getElementById("my-node"); // 通过id获取dom
      console.log(node, "node");
      domtoimage
        .toJpeg(node)
        .then((dataUrl) => {
          //输出图片的Base64,dataUrl
          // 下载到PC
          const a = document.createElement("a"); // 生成一个a元素
          const event = new MouseEvent("click"); // 创建一个单击事件
          a.download = "用餐凭证"; // 设置图片名称没有设置则为默认
          a.href = dataUrl; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
  },
};
</script>
<style scoped>
.reckoningForm /deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.reckoningForm /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.searchCon {
  display: inline-block;
}
.searchItem:nth-child(1) {
  margin-left: 20px;
}
.searchItem {
  display: inline-block;
  margin-right: 20px;
}
.searchItem span {
  padding: 0 20px;
  background: #f5f7fa;
  color: #909399;
  line-height: 40px;
  height: 40px;
  display: none;
  border: 1px solid #dcdfe6;
  border-right: none;
  border-radius: 4px;
}
.batchPic {
  width: 450px;
}
</style>
